<template>
<div class="row text-uppercase align-items-center border-bottom">
  <div id="mobile" class="col-auto ms-2 d-block d-sm-none" style="color:rgb(77,124,254);">
    <span role="button" class="fs-5" @click="MobileNav">
      <i class="bi bi-list"></i>
    </span>
  </div>
  <div id="tabletPC" class="col-auto ms-2 d-none d-sm-block" style="color:rgb(77,124,254);">
    <span role="button" class="fs-5" @click="NavDetails">
      <i class="bi bi-list"></i>
    </span>
  </div>
  <div id="logo" class="col" style="color:rgb(77,124,254);">
    <span class="fw-bolder">Hosho</span>
  </div>
  
  <!-- userProfile -->
  <div id="userProfile" class="col-auto">
    <a href="" data-bs-toggle="dropdown" aria-expanded="false">
      <div class="rounded-circle border border-dark"  >
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
          </svg>
      </div>
    </a>
    <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">
      <div class="text-center border-end py-2">
        <div class="rounded-circle border border-dark m-auto" style="height: 4rem;width: 4rem;">
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
          </svg>
        </div>
        <span style="font-size: 80%"> {{displayName}} </span>
      </div>
      <div class="dropdown-divider"></div>
      <div>
        <router-link class="dropdown-item" :to="{name: 'Profile'}">
          Chg app domain
        </router-link>
        <a class="dropdown-item" @click="SIGN_OUT">Logout</a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {isLoggin} from '@/helpers';
import router from '../../router';
import { mapActions } from 'vuex';
import { SIGN_OUT } from '../../store/auth/constants';
import { toggleNavDetails, toggleMobileNav } from "../Navigation/navState";
import { getAuthID } from '../../helpers';

export default {
  name:"NavBar",
  data(){
    return{
      displayName:''
    }
  },
  setup(){
    const NavDetails =()=>{
      toggleNavDetails()
    }
    const MobileNav =()=>{
      toggleMobileNav()
    }
    return{ 
      NavDetails,MobileNav
    }
  },
  methods:{
    ...mapActions('auth',[SIGN_OUT]),
    goToDashboard(){
      isLoggin()===true ? router.push('/') : router.push('/login')
    },
    async User() {
      const user = await getAuthID();
      this.applicationDomain = user.applicationDomain
      this.displayName = user.displayName
    },
  },
  mounted(){
    this.User()
  }
}
</script>

<style>

</style>