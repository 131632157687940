import { AuthApi, SetAuthHeader } from './apis';
import { createToast } from 'mosha-vue-toastify';

function login(loginCredentials) {
  return AuthApi().post('/api/user/v1/auth', loginCredentials)
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
      createToast(error.toString(),
      {
        hideProgressBar: 'true',
        showIcon: 'true',
        type: 'warning',
        showCloseButton: 'true',
      })
    });
}

function logout() {
  SetAuthHeader();
  return AuthApi().post('/api/user/v1/session/terminate')
    .then((response) => response.data)
    .catch((error) => {
      createToast(error.toString(),
      {
        hideProgressBar: 'true',
        showIcon: 'true',
        type: 'warning',
        showCloseButton: 'true',
      })
    });
}

const services = {
  login,
  logout,
};

export default services;
