import { createRouter, createWebHistory } from 'vue-router'
import { nonAuthenticatedRoute, authenticatedRoute } from '@/helpers';
import NotFound from '../views/NotFound.vue';
import Dashboard from '../views/Dashboard.vue';

//remember to set meta for the breadcrumbs function

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    visibility: false,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter: [authenticatedRoute],
    component: Dashboard,
    icon: 'bi-speedometer2'

  },
  {
    path: '/provence-list',
    name: 'ProvenanceList',
    beforeEnter: [authenticatedRoute],
    component: () => import(/* webpackChunkName: "ViewBlock" */ '../views/Blockchain/ProvenanceList.vue'),
    // meta: { name: 'ProvenanceList' },
    icon: 'bi-list-check'

  },
  {
    path: '/Provenance',
    name: 'Provenance',
    beforeEnter: [authenticatedRoute],
    component: () => import(/* webpackChunkName: "VerifyBlock" */ '../views/Blockchain/Provenance.vue'),
    meta: { name: 'Provenance' },
    icon: 'bi-link-45deg'
    // icon: 'bi-boxes'

  },
  {
    path: '/verify-block',
    name: 'VerifyBlock',
    beforeEnter: [authenticatedRoute],
    component: () => import(/* webpackChunkName: "VerifyBlock" */ '../views/Blockchain/VerifyBlock.vue'),
    meta: { name: 'VerifyBlock' },
    icon: 'bi-box'
  },
  {
    path: '/create-block',
    name: 'CreateBlock',
    beforeEnter: [authenticatedRoute],
    component: () => import(/* webpackChunkName: "CreateBlock" */ '../views/Blockchain/CreateBlock.vue'),
    meta: { name: 'CreateBlock' },
    icon: 'bi-plus-square'
    // icon: 'bi-box'
  },
  {
    path: '/profile',
    name: 'Profile',
    beforeEnter: [authenticatedRoute],
    visibility: false,
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    meta: { name: 'Profile' }
  },
  
  {
    path: '/login',
    name: 'login',
    visibility: false,
    beforeEnter: [nonAuthenticatedRoute],
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/404',
    name: '404',
    visibility: false,
    component: NotFound
  },  
  { 
    path: '/:pathMatch(.*)*', 
    redirect: '/404',
    visibility: false
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
