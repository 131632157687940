<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb" >
      <li class="breadcrumb-item" v-for="(bread,index) in breadList" :key="index" aria-current="page">
        <router-link class="breadcrumb-item" :to=bread.path :class="index===(this.lenght-1) ? 'active' : '' ">
          {{bread.name}}
        </router-link>
      </li>
    </ol>
  </nav>
</template>


<script>
export default {
  data() {
    return {
      breadList: [],
      lenght:''
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  },
  methods: {
    isHome(route) {
      return route.name === "DASHBOARD";
    },
    getBreadcrumb() {
      let matched = this.$route.matched;
      // if (!this.isHome(matched[0])) {
      //   matched = [{ path: "/dashboard", meta: { name: "Dashboard" } }].concat(matched);
      // }
      this.breadList = matched;
      this.lenght = this.breadList.length
      // console.log(this.breadList)
      // console.log('lenght',this.lenght)
    }
  },
};
</script>

<style scoped>
/* .hover:hover{
  font-weight: bold;
} */
</style>