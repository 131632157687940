import authServices from '../../services/auth-service';
import { SIGN_IN, SIGN_OUT,SIGN_IN_START, SIGN_IN_END } from '../auth/constants';
import { saveAuthInfo, removeAuthInfo, checkIsAdminRole } from '../../helpers/auth-helper'
import router from '../../router';
import { createToast } from 'mosha-vue-toastify';


const state = {
  isSigningIn: false,
};

const getters = {
};

const actions = {
  [SIGN_IN]({ commit }, loginCredentials) {
    commit(SIGN_IN_START);
    authServices.login(loginCredentials).then(
      (data) => {
        if (checkIsAdminRole(data)) {
          saveAuthInfo(data);
          router.push('/')
        } else {
          createToast('You are not admin',{hideProgressBar: 'true'}); 
          commit(SIGN_IN_END);
        }
      },
      (error) => {
        error==="Forbidden." ? createToast("Wrong user id or password.",{hideProgressBar: 'true'}):createToast(error,{hideProgressBar: 'true'});
        commit(SIGN_IN_END);
      },
    );
  },
  [SIGN_OUT]() {
    authServices.logout();
    removeAuthInfo();
    router.push('/login')
  },
};

const mutations = {
  [SIGN_IN_START](state) {
    state.isSigningIn = true;
  },
  [SIGN_IN_END](state) {
    state.isSigningIn = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export * from './constants.js'