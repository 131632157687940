import DataServices from '../../services/data-service'
import { GET_DATA, GET_DATA_START, GET_DATA_SUCCESS, GET_DATA_FAILURE, SEARCHED_DATA } from './constants'

const initialState = {
    gettingDataStart:false,
    data : [],
    oriData : [],
};

const state = {
    ...initialState,
};
  
const getters = {
    getData: (state) =>  {
          console.log(state,"state")
      return state.data;
    }
};
const actions = {
     [GET_DATA]({ commit }){
        commit(GET_DATA_START);
        DataServices.GET().then(
            (data)=>{
                commit(GET_DATA_SUCCESS, data);
            },
            ()=>{commit(GET_DATA_FAILURE)}
        )
     },
     [SEARCHED_DATA]({ commit, state }, searchKeyword){
        const data = [state.oriData];
        const searchedData = searchKeyword ? data.filter(rows=> rows.colums.toLowerCase().includes(searchKeyword.toLowerCase())) : data 
        commit(SEARCHED_DATA,searchedData)
    },
};
  
const mutations = {
    [GET_DATA_START](state){
        state.gettingDataStart = true;
    },
    [GET_DATA_SUCCESS](state, data) {
        state.gettingDataStart = false;
        state.data = data;
        console.log("index",state.data)
        state.oriData = data;
      },
    [GET_DATA_FAILURE](state) {
        state.gettingDataStart = false;
        state.data = [];
        state.oriData = [];
    },
    [SEARCHED_DATA](state, data){
        state.data = data;
    }
};
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };

export * from './constants.js'

  
  
