<template>
<div class="container-fluid">
  <NavBar/>
  <div class="row">
    <div class="col-md-auto m-0 p-0" :class="{'col-sm-auto d-block':!showDtls, 'd-sm-none d-md-block':showDtls}" style="max-width:22%">
      <navigation/>
    </div>
    <div class="col-md col-sm min-vh-100 bg py-2 px-3 py-sm-3 px-sm-4" style="min-width:78%">
      <breadcrumb>{{Breadcrumb}}</breadcrumb>
      <router-view></router-view>
    </div>
  </div>
  <div class="row bg-success text-white"> footer </div>
</div>
</template>

<script>
import Navigation from './components/Navigation/Navigation.vue';
import "bootstrap-icons/font/bootstrap-icons.css";
import NavBar from './components/Navigation/NavBar.vue';
import Breadcrumb from './components/Breadcrumb.vue';
import { state } from "../src/components/Navigation/navState"
import { computed } from '@vue/reactivity';


export default {
  name:'App',
  components: { 
    Navigation, 
    NavBar,
    Breadcrumb 
  },
  setup(){
    const MobileNav = computed(()=>{
      return state.showMobileNav
    })

    const showDtls = computed(()=>{
      return state.showDetails
    })

    return{ MobileNav,showDtls }
  }
}
</script>

<style scoped>
.bg{
  /* background: linear-gradient(
        135deg,
        rgb(228, 229, 230),
        rgba(248, 250, 251) 100%
      ); */
  background: rgb(190, 192, 194);    
}
</style>
