<template>
  <div class="col">
        <router-view></router-view>
  </div>
</template>

<script>
export default {
  name:'Blockchain',
  data(){
    return{
    }
  },
  setup(){
    return{
    }
  },
  methods:{
  },
}
</script>


