import { createStore,createLogger } from 'vuex'
import auth from './auth'
import data from './data'


const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    auth,
    data
  },
  strict: true,
  plugins: debug ? [createLogger()] : [],
})
