const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const SIGN_IN_START = 'SIGN_IN_START';
const SIGN_IN_END = 'SIGN_IN_END';
const AUTH_TOKEN_KEY = 'auth_token';
const AUTH_ID = 'auth_id';
const ADMIN_ROLE = 'Hosho Admin';

export { 
    SIGN_IN, 
    SIGN_OUT, 
    SIGN_IN_START, 
    SIGN_IN_END,
    AUTH_TOKEN_KEY,
    AUTH_ID,
    ADMIN_ROLE
}