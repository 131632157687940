const GET_DATA = 'GET_DATA'; 
const GET_DATA_START = 'GET_DATA_START'; 
const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
const GET_DATA_FAILURE = 'GET_DATA_FAILURE';
const UPDATE_DATA_START = 'UPDATE_DATA_LIST_START';
const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_LIST_SUCCESS';
const UPDATE_DATA_FAILURE = 'UPDATE_DATA_LIST_FAILURE';
const SEARCHED_DATA = 'SEARCHED_DATA';

export {
    GET_DATA,
    GET_DATA_START,
    GET_DATA_SUCCESS,
    GET_DATA_FAILURE,
    UPDATE_DATA_START,
    UPDATE_DATA_SUCCESS,
    UPDATE_DATA_FAILURE,
    SEARCHED_DATA 
};
