<template>
<nav class="d-sm-block" :class="{'d-none': MobileNav}">
    <ul class="list-group" v-for="route in routes" :key="route.path" v-show="route.visibility !== false">
        <router-link class="text-muted text-decoration-none" :to="{ name : route.name }">
            <li class="list-group-item list-group-item-action list-group-item-light border-0" @click="mobileGo">
                <span style="white-space: nowrap;">
                    <span data-bs-toggle="tooltip" :title='route.name' class="d-flex">
                        <span class="fs-5" >
                            <i class="bi" :class="route.icon"></i> &nbsp;
                        </span>
                        <span :class="[showDtls===true ? 'd-md-none':'d-md-block d-sm-none']">
                            {{route.name}}
                        </span>
                    </span>
                </span>
            </li>
        </router-link>
    </ul>
</nav>
</template>

<script>
import routes from '../../router';
import { state, toggleMobileNav } from "../Navigation/navState"
import { computed } from '@vue/reactivity';


export default {
    name:'Home',
    setup(){
        const showDtls = computed(()=>{
            return state.showDetails
        })
        const MobileNav = computed(()=>{
            return state.showMobileNav
        })

        const mobileGo = () => {
           return toggleMobileNav()
        }
        return{ showDtls, MobileNav, mobileGo }
    },
    data(){
        return {
            routes: routes.options.routes,
        }
    },
}
</script>
