import { reactive } from "vue";

export const state = reactive({
    showDetails: true,
    showMobileNav: true
});

export const toggleNavDetails=()=>{
    state.showDetails = !state.showDetails
    return state.showDetails
};

export const toggleMobileNav=()=>{
    state.showMobileNav = !state.showMobileNav
    return state.showMobileNav
}

export const selectedDomain=(x)=>{
    // console.log("selectedAppDomainx is",x)
    const selectedAppDomainx = x
    return selectedAppDomainx
}
