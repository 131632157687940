import axios from "axios";
import{getAuthToken} from '../helpers/auth-helper'

export function AuthApi (){
    return (
        axios.create({
            baseURL: process.env.VUE_APP_GENERIC_API_DOMAIN,
            headers: { 
                Accepts: "application/json",
                "Content-type": "application/json", 
            }
          })
    )
}

export function SetAuthHeader() {
    // if (getAuthToken() !== null) axios.defaults.headers.common.Authorization = `Bearer ${getAuthToken()}`;
    axios.defaults.headers.common.Authorization = `Bearer ${getAuthToken()}`;
}

export function DataApi (){
    return (
        axios.create({
            baseURL: process.env.VUE_APP_DATA_API_DOMAIN,
            headers: { 
                Accepts: "application/json",
                "Content-type": "application/json", 
                "Authorization" : `Bearer ${getAuthToken()}`
            }
        })
    )
}
