import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { VueCookieNext } from 'vue-cookie-next';

import "bootstrap/dist/css/bootstrap.min.css";
import 'mosha-vue-toastify/dist/style.css'
import 'bootstrap';

 
VueCookieNext.config({
    expire: '1h',
    path: '/',
    sameSite: 'Strict',
    // secure: true,
  });

createApp(App)
.use(store)
.use(router)
.use(VueCookieNext)
.mount('#app')

