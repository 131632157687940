import { createToast } from 'mosha-vue-toastify';
import { removeAuthInfo } from '../helpers';
import { AuthApi, DataApi, SetAuthHeader} from './apis';
// import { AuthApi, SetAuthHeader} from './apis';
// import data from '../../data.json'

// async function GET(){
//     return data
// }


async function GetData(){
    return await DataApi().get('api/hosho/v1/chain',)
        .then((response) => {
            return response.data
        })
        .catch(error=>{
            console.log(error.response)
            if(error.response.status === 403){
                removeAuthInfo();
            } 
            createToast(error.toString(),
            {
              hideProgressBar: 'true',
              showIcon: 'true',
              type: 'warning',
              showCloseButton: 'true',
            })
        })
}

async function GetProvenance(provenanceId){
    SetAuthHeader()
    return await DataApi().get('api/hosho/v1/chain/' + provenanceId)
      .then(response=>{
          return response.data
      })
      .catch(error=>{
          createToast(error.toString(),
          {
            hideProgressBar: 'true',
            showIcon: 'true',
            type: 'warning',
            showCloseButton: 'true',
          })
      })
}

function VerifyBlock(blkData){
    SetAuthHeader()
    // return DataApi().post('api/hosho/v1/verifyBlock', data) //return w/ msg
    // return DataApi().post('api/traceability/v3/verifyBlock', data) //CORS error
    return AuthApi().post('api/traceability/v3/verifyBlock', blkData)
      .then(res=>{
        return res.data.verification
      })
      .catch(error=>{
        if(error.response.status === 500){
          createToast('Unable to verify a bolck. Please try again later.',
          {
            timeout: 3000,
            transition: 'slide',
          })
        } else {
          createToast(error.toString(),
          {
            timeout: 3000,
            transition: 'slide',
          })
        }
      })
}

// async function GETBLK(entryHash){
//     console.log("entryHash",entryHash)
//     await AuthApi().get('api/traceability/v3/block/' + entryHash)
//         .then(res=>{
//             const block = res.data
//             return block
//         })
//         .catch(error=>{
//             createToast(error.toString(),
//             {
//               hideProgressBar: 'true',
//               showIcon: 'true',
//               type: 'warning',
//               showCloseButton: 'true',
//             })
//             console.log(error)
//         })
// }

async function CreateBlock(data){
    SetAuthHeader()
    return await AuthApi().post('api/traceability/v3/block', data)
    // return await DataApi().post('api/hosho/v1/submitBlock', data)
        .then((response) => {
            const entryHash = response.data
            return entryHash
        })
        .catch(error=>{
            createToast(error.toString(),
                {
                hideProgressBar: 'true',
                showIcon: 'true',
                type: 'warning',
                showCloseButton: 'true',
                })
        })
}

function getAppDomain() {
    // SetAuthHeader()
    return DataApi().get('/api/hosho/v1/appdomain')
      .then((response) => {
        return response.data;
      })
      .catch(error=>{
        createToast(error.toString(),
        {
          hideProgressBar: 'true',
          showIcon: 'true',
          type: 'warning',
          showCloseButton: 'true',
        })
        console.log(error)
    })
  }




const DataServices = {
    GetData,
    // GETBLK,
    GetProvenance,
    CreateBlock,
    VerifyBlock,
    getAppDomain
};

export default DataServices;

